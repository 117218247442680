import React from 'react'
import useForm from '../modules/formWithSubmit/useForm'
import { Container,Row,Form,Button} from 'react-bootstrap'
import axios from "axios"
import GoogleFormHandler from '../modules/googleFormHandler/GoogleFormHandler'

function Contact() {
    const func = (data) =>{
        axios.post("https://docs.google.com/forms/u/0/d/e/1FAIpQLSckuT7S7_mCj5QDPXAAkM_QfwuNmuyn41GhTlYfPJ40JVd8sg/formResponse",data).then(function(response){
        })
    }
    const [values,handleChange,handleSubmit] = useForm(func)
    

  return (
    < >
      <Row style={{margin: "3% 15% 3% 15%", textAlign: "left"}}>
        <Form
          action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSckuT7S7_mCj5QDPXAAkM_QfwuNmuyn41GhTlYfPJ40JVd8sg/formResponse"
          method="POST"
          target="contact-iframe"
        >
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="entry.1398863738"
              type="text"
              placeholder="Enter Name"
              value={values["name"]}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name="entry.139909245"
              type="email"
              placeholder="Enter email"
              value={values["email"]}
              onChange={handleChange}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="contact">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              name="entry.281437038"
              type="number"
              placeholder="9876543210"
              value={values["contact"]}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control
              name="entry.1721582285"
              type="text"
              placeholder="City"
              value={values["city"]}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Your query</Form.Label>
            <Form.Control
              name="entry.1733638825"
              as="textarea"
              placeholder="Write your query here"
              value={values["query"]}
              onChange={handleChange}
            />
          </Form.Group>
          {/* <Form.Group  className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <GoogleFormHandler id="contact-iframe" title="Form Submitted">
          Your Query has been Submitted. You can now go to the home page.
        </GoogleFormHandler>
      </Row>
    </>
  );
}

export default Contact