
import React from 'react';

import img3 from '../assets/why_edrive/why_edrive_1.png'
import img4 from '../assets/why_edrive/why_edrive_2.png'
import img5 from '../assets/why_edrive/why_edrive_3.png'
import img6 from '../assets/why_edrive/why_edrive_4.png'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


function Mcarousel(props) {

  
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};

  return <div>
     
<Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={true}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  // customTransition="all .5"
  transitionDuration={300}
  containerClass="carousel-container"
  removeArrowOnDeviceType={null}
  deviceType={props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
 renderDotsOutside={false}

>
<div>
  <img src={img3}/>
  <p>Book with just<br/>few taps of your <br/> phone with seamless<br/>payment</p>
</div>
<div><img src={img4}/> <p>Fares that are easier<br/>on your pocket.</p></div>
<div><img src={img5}/> <p>Range is never an excuse,<br/> we got 100+km on single charge.</p></div>
<div><img src={img6}/> <p>Bicycle architure allows fantastic maneuverability<br/> in jam packed traffic.</p></div>
</Carousel>;

  </div>;
}

export default Mcarousel;

// function Mcarousel() {

//   const responsive = {
//     superLargeDesktop: {
//       // the naming can be any, depends on you.
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 3
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1
//     }
//   };

//   return <div>
//     <Carousel responsive={responsive}>
//   <div><img src={img8}/></div>
//   <div><img src={img8}/></div>
//   <div><img src={img8}/></div>
//   <div><img src={img8}/></div>
// </Carousel>;
//   </div>;
// }

// export default Mcarousel;


