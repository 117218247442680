import React,{useState,useEffect} from 'react'
import {Container,Row,Col,Image,Button,Accordion,Table} from 'react-bootstrap';
import img1 from '../assets/logo/edrive_logo.png'
import img2 from '../assets/intro/newbike.png'
import '../sass/invstyle.scss'
import { PieChart, Pie, Cell,ResponsiveContainer,Label} from 'recharts';
import Slider from 'react-input-slider';
import {Link} from "react-router-dom";


// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };


function Invest(props) {

    const [state, setState] = useState(1);

    const data = [
        { name: 'Group A', value: 24973*state},
        { name: 'Group B', value: 75000*state }
      ];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const [isMobile, setIsMobile] = useState(3)
     
    //choose the screen size 
    const handleResize = () => {
        
        if (document.documentElement.clientWidth >= 1296) {
            setIsMobile(3)
        } else if(document.documentElement.clientWidth >= 800) {
            setIsMobile(2)
        }
        else
        {setIsMobile(1)}
      }
      
      // create an event listener
      useEffect(() => {
        window.addEventListener("resize", handleResize)
      })
      useEffect(() => {
        handleResize()
      }, []);
    useEffect(() => {
      func()
    }, [state])
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
      
        return (
            <>

          <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {/* <p style={{backgroundColor:"black"}}>{24973*state}</p> */}
            {/* {24973*state} */}
            {`${(percent * 100).toFixed(2)}%`}
          </text>
          {/* <text  x={x} y={y} fill="black" style={{margin:"0 10% 0 0"}} textAnchor="middle" dy={8}>
             { x>cx?(state*123):(state)}
          </text> */}
          {isMobile===1?(<>
              <path d={`M${sx},${sy}L${mx},${my}L${ex}`} stroke="black" fill="none" />
              <text x={ex + (cos >= 0 ? 1 : -1) * -20} y={ey+2} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#333">{cos>=0?(` ${state*24973}`):(` ${state*75000}`)}</text></>
          ):(<>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke="black" fill="none" />
         <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#333">{cos>=0?(` ${state*24973}`):(` ${state*75000}`)}</text>
          </>)}
          
            </>
          
        );
      };
      
  const func = () =>{
     
    return(
        <div style={{ width: '100%', height: 300 }}>
              <input type="number" min={1} max={50} value={state}
               onChange={e=>setState(Math.max(1,Math.min(e.target.value,50)))} />
        {/* <div> */}
        <ResponsiveContainer>
        <PieChart id="percent" >
        {/* <h1 style={{margin:"30% 30% 30% 30%"}}>afdsf</h1> */}
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            // label

          >
              
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}

          </Pie>

        </PieChart>
        </ResponsiveContainer>
        {/* </div> */}

        </div>
    );
  
  }
    if(isMobile!=1)
 { return (
    <div>
        <>
            <Row className="grad" style={{height:"100vh"}} >
                <Col md={5} >
                <Image fluid="true" style={{width:"15%",marginTop:"20%" }} src={img1}/>    
            <div style={{margin:"10% 0 0 15%",textAlign:"left" ,fontSize:"1.5rem"}}>
            <p>Earn fixed monthly return of 21% by joining E Drives buy & lease program</p>
            </div>
            <Button size="lg" style={{borderRadius:"0",marginTop:"30%",backgroundColor:"#95c93d",fontWeight:"bold"}} variant="light" href="https://buylease.edrives.in/">Login</Button>&emsp;
            <Button size="lg" style={{borderRadius:"0",backgroundColor:"#95c93d",marginTop:"30%",fontWeight:"bold"}} variant="light" ><Link style={{textDecoration:"none",color:"black"}} to="/Contact">Contact Us</Link> </Button>
            </Col>
                <Col md={7}>
                    <Image style={{margin:"20%",width:"60%"}}  fluid="true" src={img2}/>
                </Col>
            </Row>
            <Row style={{height:"100vh"}}>

                <div style={{fontSize:"2rem",margin:"5% 0 1% 0"}}>How do you earn by investing in E DRIVES?</div  >


              
            <iframe width="100%" height="500px" src="https://www.youtube.com/embed/kfqzpk7wEHE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Row>
            <Row>
            <div>
          <h1 style={{marginTop:"2"}}>Lease Program Calculator</h1>
            <Slider
            xstep={1}
            xmax={50}
            xmin={1}
            styles={{
                track: {
                backgroundColor: 'gray'
                },
                active: {
                backgroundColor: '#95c93d'
                },
                thumb: {
                width: 20,
                height: 20
                },
                disabled: {
                opacity: 0.5
                }
            }}
                axis="x"
                x={state}
                onChange={({ x }) => setState(x)}
            />
            </div>
            </Row>
            <Row style={{height:"80vh",margin:"0% 15% 0 15%",alignItems:"center"}}  >
                
                {func()}
            
               
               <div style={{fontSize:"1.2rem"}}>
                   <span className="dot2"></span> Investment Amount &emsp;
                   <span className="dot1"></span>  Total Profit

                   </div>
               
           
            <div>
            <h4>
            Investment plan:
            </h4>

            </div>
            
                <Table striped bordered hover >
                <tbody>
                    <tr>
                        <td>IRR</td>
                        <td>21%</td>
                    </tr>
                    <tr>
                        <td>Tenure</td>
                        <td>36months</td>
                    </tr>
                    <tr>
                        <td>Investment Amount</td>
                        <td>{75000*state}</td>
                    </tr>
                    <tr>
                        <td>Monthly Payouts</td>
                        <td>{2777*state}</td>
                    </tr>
                    <tr>
                        <td>Total Payment</td>
                        <td>{99973 *state}</td>
                    </tr>
                    <tr>
                        <td>Total Profit</td>
                        <td>{24973*state}</td>
                    </tr>
                    
                </tbody>
                </Table>
                    {/* <Button variant="light" style={{backgroundColor:'#95c93d'}} onClick={func()}>Calculate</Button> */}
            </Row>
            <Row style={{margin:"3% 10% 3% 10%",height:"100vh"}}>
            <div><h1>FAQs</h1></div>
            <Accordion  id="ifaq" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Q: 1) What is the assurance given for the investment?</Accordion.Header>
                    <Accordion.Body>
                    A. In the case of event where the Company is taken over by way of takeover or is declared as Bankrupt or for any other reason the ownership of E Drives gets transferred to a strategic partner, the balance amount will be paid to the investor in cash on pro rata basis or kind or in form of equity or in form of assets whichever is applicable on mutual consent of all parties involved, within 60 days from the date of occurrence of such event. The management will ensure the best interest of all investor is taken care of in any such case.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Q: 2) The E-Bike will be under whose name and who will pay taxes/ IT? </Accordion.Header>
                    <Accordion.Body>
                    A. The E Bike will be under the name of investor and the investor will buy it from the Company and lease it to the company (E Drives). Thus, the invoice will be created under the name of investor. Also, the tax liability will be on the investor as per the standard investment norms for any kind of investment.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Q: 3) Can an investor decide on any other Product to be bought?</Accordion.Header>
                    <Accordion.Body>
                    A. No, Its customized as per the company’s requirement

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Q: 4) What happens if the E Bike  is damaged in an accident?</Accordion.Header>
                    <Accordion.Body>
                    A. E Drives will take care of the damage and get it operation ready as soon as possible, however rental doesn't stop in this case.

                    </Accordion.Body>
                </Accordion.Item>
               
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Q: 5) What if the Batteries will be stolen out of the Scooter in these 36  months?</Accordion.Header>
                    <Accordion.Body>
                    A. Batteries is E Drives responsibility and it will not affect the rentals in any way.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Q: 6) What happens to the E Bike after 36 months?</Accordion.Header>
                    <Accordion.Body>A. The ownership will be transferred from investor to E Drives after 36 months post the payment of residual value made from E Drives to the investor.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Q: 7) Who will Pay the electricity bill for the E Bike?</Accordion.Header>
                    <Accordion.Body>A. E Drives will pay the electricity bill.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>Q: 8) who will be responsible for Insurance of the E Drives?</Accordion.Header>
                    <Accordion.Body>A. E Drives will be liable for the insurance.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>Q: 9) Is these E-Bike designed and developed by E Drives?</Accordion.Header>
                    <Accordion.Body>A. Yes the vehicle is being designed and developed by E Drives.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>Q: 10) What if I want to terminate my agreement before 3 years?</Accordion.Header>
                    <Accordion.Body>A. The agreement cannot be terminated if the company is paying the rentals on </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>Q: 11) How many vehicles can one individual buy and lease to E Drives ?</Accordion.Header>
                    <Accordion.Body>A. An individual can opt for 1 and go upto 50 vehicles</Accordion.Body>
                </Accordion.Item>
            </Accordion> 
                    <div id="km"/>
            </Row>
            <Button size="lg" style={{borderRadius:"0",margin:"1% 0 2% 0",backgroundColor:"#95c93d"}} variant="light" ><Link style={{textDecoration:"none",color:"black"}} to="/Contact">Contact Us</Link> </Button>

        </>
    </div>
  );}
  else{
      return (
          <div>
        <>
            <Row style={{height:"100vh"}}className="grad"  >
                <Col xs={12} >
                    <Image   style={{margin:"20% 0 5% 0",width:"80%"}}fluid="true" src={img2}/>
                </Col>


            <Col xs={12} style={{marginBottom:"10%"}}>
            <div style={{margin:"0% 0 0 8%",textAlign:"left" ,fontSize:"1.5rem"}}>
            <p>Earn fixed monthly return of 21% by joining E Drives buy & lease program</p>
            </div>

            <Button size="lg" style={{borderRadius:"0",margin:"5% 0 0 0",backgroundColor:"#95c93d"}} variant="light" href="https://buylease.edrives.in/">Login</Button>{' '}
            <Button size="lg" style={{borderRadius:"0",margin:"5% 0 0% 0",backgroundColor:"#95c93d"}} variant="light" ><Link style={{textDecoration:"none",color:"black"}} to="/Contact">Contact Us</Link> </Button>


            </Col>
            </Row>
            <Row style={{margin:"10% 0 10% 0",height:"100vh"}}>

                <div style={{fontSize:"2rem",margin:"0% 0 1% 0"}}>How do you earn by investing in E DRIVES?</div  >



            
            <iframe width="100%" height="300px" src="https://www.youtube.com/embed/kfqzpk7wEHE?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Row>
            <Row style={{margin:"0 1% 0 1%",height:"120vh"}}>
            <div>
          <h1 style={{marginTop:"2%"}}>Lease Program Calculator </h1>

            <Slider
            xstep={1}
            xmax={50}
            xmin={1}
            styles={{
                track: {
                backgroundColor: 'gray'
                },
                active: {
                backgroundColor: '#95c93d'
                },
                thumb: {
                width: 20,
                height: 20
                },
                disabled: {
                opacity: 0.5
                }
            }}
                axis="x"
                x={state}
                onChange={({ x }) => setState(x)}
            />
            </div>
            
                {func()}
                 
            
            <div style={{fontSize:"1.2rem"}}>
                   <span className="dot2"></span> Investment Amount <br/>
                   <span className="dot1"></span>  Total Profit

                   </div>
            
            <div>   <h4>
            Investment plan:
            </h4>
            </div>
                <Table striped bordered hover>
        


            
                <tbody>
                    <tr>
                        <td>IRR</td>
                        <td>21%</td>
                    </tr>
                    <tr>
                        <td>Tenure</td>
                        <td>36months</td>
                    </tr>
                    <tr>
                        <td>Investment Amount</td>
                        <td>{75000*state}</td>
                    </tr>
                    <tr>
                        <td>Monthly Payouts</td>
                        <td>{2777*state}</td>
                    </tr>
                    <tr>
                        <td>Total Payment</td>
                        <td>{99973 *state}</td>
                    </tr>
                    <tr>
                        <td>Total Profit</td>
                        <td>{24973*state}</td>
                    </tr>
                    
                </tbody>
                </Table> 
            </Row>
           
            <Row id="ifaq" style={{margin:"3% 0% 3% 0%"}}>
                <h4>FAQ</h4>
            <Accordion  id="ifaq" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Q: 1) What is the assurance given for the investment?</Accordion.Header>
                    <Accordion.Body>
                    A. In the case of event where the Company is taken over by way of takeover or is declared as Bankrupt or for any other reason the ownership of E Drives gets transferred to a strategic partner, the balance amount will be paid to the investor in cash on pro rata basis or kind or in form of equity or in form of assets whichever is applicable on mutual consent of all parties involved, within 60 days from the date of occurrence of such event. The management will ensure the best interest of all investor is taken care of in any such case.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Q: 2) The E-Bike will be under whose name and who will pay taxes/ IT? </Accordion.Header>
                    <Accordion.Body>
                    A. The E Bike will be under the name of investor and the investor will buy it from the Company and lease it to the company (E Drives). Thus, the invoice will be created under the name of investor. Also, the tax liability will be on the investor as per the standard investment norms for any kind of investment.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Q: 3) Can an investor decide on any other Product to be bought?</Accordion.Header>
                    <Accordion.Body>
                    A. No, Its customized as per the company’s requirement

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Q: 4) What happens if the E Bike  is damaged in an accident?</Accordion.Header>
                    <Accordion.Body>
                    A. E Drives will take care of the damage and get it operation ready as soon as possible, however rental doesn't stop in this case.

                    </Accordion.Body>
                </Accordion.Item>
               
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Q: 5) What if the Batteries will be stolen out of the Scooter in these 36  months?</Accordion.Header>
                    <Accordion.Body>
                    A. Batteries is E Drives responsibility and it will not affect the rentals in any way.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Q: 6) What happens to the E Bike after 36 months?</Accordion.Header>
                    <Accordion.Body>A. The ownership will be transferred from investor to E Drives after 36 months post the payment of residual value made from E Drives to the investor.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Q: 7) Who will Pay the electricity bill for the E Bike?</Accordion.Header>
                    <Accordion.Body>A. E Drives will pay the electricity bill.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>Q: 8) who will be responsible for Insurance of the E Drives?</Accordion.Header>
                    <Accordion.Body>A. E Drives will be liable for the insurance.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>Q: 9) Is these E-Bike designed and developed by E Drives?</Accordion.Header>
                    <Accordion.Body>A. Yes the vehicle is being designed and developed by E Drives.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>Q: 10) What if I want to terminate my agreement before 3 years?</Accordion.Header>
                    <Accordion.Body>A. The agreement cannot be terminated if the company is paying the rentals on time</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>Q: 11) How many vehicles can one individual buy and lease to E Drives ?</Accordion.Header>
                    <Accordion.Body>A. An individual can opt for 1 and go upto 50 vehicles</Accordion.Body>
                </Accordion.Item>
            </Accordion>   
                <div id="km"/>          
            </Row>
            <Button size="" style={{borderRadius:"0",margin:"2% 0 5% 0",backgroundColor:"#95c93d"}} variant="light" ><Link style={{textDecoration:"none",color:"black"}} to="/Contact">Contact Us</Link> </Button>

        </>              
          </div>
      );
  }
}

export default Invest

