import React, { useEffect,useState } from 'react';
import img1 from '../assets/logo/edrive_logo.png'
import img2 from '../assets/intro/newbike.png'
import img3 from '../assets/why_edrive/why_edrive_1.png'
import img4 from '../assets/why_edrive/why_edrive_2.png'
import img5 from '../assets/why_edrive/why_edrive_3.png'
import img6 from '../assets/why_edrive/why_edrive_4.png'
import img7 from '../assets/how_edrive/how_edrive.png'
import img8 from '../assets/testimonials/rakesh_ji.jpg'
import img9 from '../assets/testimonials/umesh_ji.jpg'
import img11 from '../assets/intro/google-play-badge.png'
import '../sass/Homestyle.scss'
import Mcarousel from './Multicarousel'
// import {instagram} from '@fortawesome/fontawesome-svg-core'
import {Carousel,Col,Row,Container,Card,Image} from 'react-bootstrap';

function Home() {

  const [isMobile, setIsMobile] = useState(3)
 
  //choose the screen size 
  const handleResize = () => {
    
    if (document.documentElement.clientWidth >= 1296) {
      setIsMobile(3)
  } else if(document.documentElement.clientWidth >= 800) {
      setIsMobile(2)
  }
  else
  {setIsMobile(1)}
  }
  
  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  useEffect(() => {
    handleResize()
  }, []);
  
  
  // const styles = () =>{
  //   isMobile
  //   {
  //     const ret ={ backgroundColor: 'white' ,color:"black"}
  //     return ret;
  //   }
  //   else
  //   {
  //     const ret ={ backgroundColor: 'blue' ,color:"black"}
  //     return ret;
  //   }
  // }


  if(isMobile===1)
  {
    return(
      <div className='mob'>
       <>
         <Row className="gr">

         </Row>
       <Row>
            <Col xl={6} className='gr'>
            
            <Image fluid="true" style={{width:"25%",marginBottom:"3%", height:"auto"}}src={img1}/>
            <div style={{fontSize:"1.5rem",textAlign:"left" ,marginLeft:"35%"}}>
            <p>WE ARE A TEAM OF<br/>RIDERS,<br/>DESIGNERS,<br/>DREAMERS,<br/>AND<br/>DOERS.<br/></p>
          
            </div>
            <a href="https://play.google.com/store/apps/details?id=in.edrives.app.customer"><Image fluid="true" src={img11}/>  </a>         
            
            </Col>
            <Col xl={6} className='wh'>
              <Image fluid="true"style={{width:"70%",margin:"15% 0 5% 0", height:"auto"}}src={img2}></Image>
            </Col>
        </Row>
        
            
            <Row id="wed" lg={6} className='gr'>
            <Carousel variant="dark">
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={img3}
                  alt="First slide"
                  
                />
                
                 <p style={{marginBottom:"15%"}}>Book with just<br/>few taps of your <br/> phone with seamless<br/>payment</p>
               
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={img4}
                  alt="Second slide"
                />
               <p style={{marginBottom:"28.5%"}}>Fares that are easier<br/>on your pocket.</p>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={img5}
                  alt="First slide"
                  
                />
                
                 <p style={{marginBottom:"15%"}}>Range is never an excuse,<br/> we got 100+km on single charge.</p>
               
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={img6}
                  alt="Third slide"
                />
                 <p style={{marginBottom:"28.5%"}}>Bicycle architure allows fantastic maneuverability<br/> in jam packed traffic.</p>
               
              </Carousel.Item>
            </Carousel>
            </Row>
             
        
        
        <Row id="bx33">
            <Col lg={12} xl={6} className='wh'>
            <Card id="bx4"  style={{ width: '18rem' ,marginLeft:"10%",marginTop:"10%",marginBottom:"10%" ,marginRight:"10%"}}>
              <Card.Img variant="top" src={img7} />
              
            </Card>             
            </Col>
            <Col id="bx3" lg={12} xl={6} className='gr'>
              
              <div id="htb" style={{ margin:"10% 5% 10% 10%",textAlign:"left ",alignContent:""}}>
              
            <h1 >How to book?</h1>
            <div style={{fontSize:"1.2rem"}}><h4>Download the E Drive App:</h4>
            Download the app from Google play.<br/>
            <h4><br/>Book your Ride:</h4>        
            Select your Booking dates and pick your
            vehicle from your nearest pickup point.<br/><br/>
            <h4>Ride safely:</h4>
            Enjoy E Drive ride,but remember to abide by<br/>
            the traffic laws and parking policies.<br/>
            <h4><br/>End your Ride:</h4>
            Return back to our nearest drop point.
            </div>
            
              </div>
            
            </Col>
        </Row>
        <Row style={{margin:"10% 0 10% 0"}} >
        <iframe width="100%" height="200px" src="https://www.youtube.com/embed/qotaxeBdfNU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Row>
        <Row className='gr' style={{margin:"10% 0 10% 0"}}>
       
        
            <Carousel style={{marginTop:"2%"}}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={img9}
                alt="First slide"
              />
              <p><i className="fas fa-quote-left ">  </i>  My monthly expenditure on <br/>vehicle is reduced <br/>by 50%<i className="fas fa-quote-right"></i></p>
                      <p>-Mr. Umesh</p>
                      <p style={{marginBottom:"15%"}}>Zomato</p>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={img8}
                alt="Second slide"
              />

              <p><i className="fas fa-quote-left">  </i>  Its quick & fun to ride <br/>gives fantastic maneuverability <br/>in jammed packed traffic.<i className="fas fa-quote-right"></i></p>
             <p>-Mr. Rakesh</p>
             <p style={{marginBottom:"15%"}}>Swiggy</p>
  </Carousel.Item>
 
   
            </Carousel>
        </Row>
      <Row style={{margin:"10% 0 10% 0"}}>
    <iframe  width="100%" height="200px" src="https://www.youtube.com/embed/sG2Mkx8aLUs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      </Row>
     
           

        
    </>

    </div>
 
    );
  }
  else if(isMobile===2)
  {
    return(
      <div>
    <>
     <Row  className='gr'>

     </Row>

      <Row>
          <Col xl={6} className='gr'>
          
          <Image fluid="true" style={{width:"25%",marginBottom:"50px", height:"auto"}}src={img1}/>
         

          <div style={{fontSize:"1.5rem",textAlign:"left" ,marginLeft:"35%"}}>
            <p>WE ARE A TEAM OF<br/>RIDERS,<br/>DESIGNERS,<br/>DREAMERS,<br/>AND<br/>DOERS.<br/></p>
          </div>
          <a href="https://play.google.com/store/apps/details?id=in.edrives.app.customer"><Image fluid="true" src={img11}/>  </a>         
          
          </Col>
          <Col xl={6} className='wh'>
            <Image fluid="true"style={{width:"70%",margin:"15% 0 10% 0", height:"auto"}}src={img2}></Image>
          </Col>
      </Row>
      <Row id="wed" className='gr' style={{padding: "10px",borderRadius:"5px"}}> 

       <Mcarousel/>
      </Row>
     
     
      
      <Row id="bx33">
          <Col xl={6} className='wh' style={{padding: "10px",borderRadius:"5px"}}>
          <Card id="bx4"  style={{ textAlign:"center"}}>
            <Card.Img style={{width:"40%",margin:"2% 0% 2% 28%"}}variant="top" src={img7} />
            
          </Card>             
          </Col>
          <Col id="htb" xl={6} className='gr' style={{padding: "10px",borderRadius:"5px" ,color:"black"}}>
            
            <div id="bx3" style={{ margin:"10% 5% 10% 10%",textAlign:"left ",alignContent:""}}>
            <h1 >How to book?</h1>
            <div style={{fontSize:"1.2rem"}}><h4>Download the E Drive App:</h4>
            Download the app from Google play.<br/>
            <h4><br/>Book your Ride:</h4>        
            Select your Booking dates and pick your
            vehicle from your nearest pickup point.<br/><br/>
            <h4>Ride safely:</h4>
            Enjoy E Drive ride,but remember to abide by<br/>
            the traffic laws and parking policies.<br/>
            <h4><br/>End your Ride:</h4>
            Return back to our nearest drop point.
            </div>
            </div>
          
          </Col>
      </Row>
      <Row className='wh'style={{margin:"10% 0 10% 0"}}>
        <iframe width="100%" height="500px" src="https://www.youtube.com/embed/qotaxeBdfNU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Row>
        <Row className='gr'style={{margin:"10% 0 10% 0"}}>
       
        
            <Carousel style={{margin:"2% 0 0 0"}}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={img9}
                alt="First slide"
              />
              <p><i className="fas fa-quote-left ">  </i>  My monthly expenditure on <br/>vehicle is reduced <br/>by 50%<i className="fas fa-quote-right"></i></p>
                      <p>-Mr. Umesh</p>
                      <p style={{marginBottom:"15%"}}>Zomato</p>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={img8}
                alt="Second slide"
              />

              <p><i className="fas fa-quote-left">  </i>  Its quick & fun to ride <br/>gives fantastic maneuverability <br/>in jammed packed traffic.<i className="fas fa-quote-right"></i></p>
             <p>-Mr. Rakesh</p>
             <p style={{marginBottom:"15%"}}>Swiggy</p>
  </Carousel.Item>
 
   
</Carousel>
</Row>
      <Row className='wh' style={{margin:"10% 0 10% 0"}}>
    <iframe  width="100%" height="500px" src="https://www.youtube.com/embed/sG2Mkx8aLUs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      </Row>   
       
      
      

    </>
</div>
);  
  }
  else
  {
    return(
      <div>
    <>
     

      <Row>
          <Col xl={6}  className='gr'>
          
          <Image fluid="true" style={{width:"25%",marginBottom:"50px", height:"auto"}}src={img1}/>
          <div style={{fontSize:"1.5rem",textAlign:"left" ,marginLeft:"35%"}}>
            <p>WE ARE A TEAM OF<br/>RIDERS,<br/>DESIGNERS,<br/>DREAMERS,<br/>AND<br/>DOERS.<br/></p>
          </div>
          <a href="https://play.google.com/store/apps/details?id=in.edrives.app.customer"><Image fluid="true" src={img11}/>  </a>         
          
          </Col>
          <Col xl={6} className='wh'>
            <Image fluid="true"style={{width:"70%",marginTop:"15%", height:"auto"}}src={img2}></Image>
          </Col>
      </Row>
        <Row id="wed">
          <Col lg={6} className='wh'>
          <h2 style={{marginTop:'15px'}}>Why E drives?</h2>
          <Row style={{margin:"10% 0% 10% 0%"}}>
            <Col >
            <Card className="b1" style={{ width: '18rem' }}>
            <Card.Img id="ef1" variant="top" src={img3} />
            <Card.Body>
              <Card.Title>Easy to book</Card.Title>
              <Card.Text>
               Book with just<br/>few taps of your <br/> phone with seamless<br/>payment
              </Card.Text>
             
            </Card.Body>
          </Card> 
            </Col>
            <Col >
            <Card className="b1" style={{ width: '18rem' }}>
            <Card.Img variant="top" src={img4} />
            <Card.Body>
              <Card.Title>Economical</Card.Title>
              <Card.Text>
               Fares that are easier<br/>on your pocket.<br/><br/><br/>
              </Card.Text>
             
            </Card.Body>
          </Card>  
           
            </Col>
          </Row>
           
          
          </Col>
          <Col lg={6}  className='gr'>
          <Row style={{margin:"16.5% 0% 10% 0%"}}>
            <Col>
            <Card className="b1" style={{ width: '18rem' }}>
            <Card.Img variant="top" src={img5} />
            <Card.Body>
              <Card.Title>Never worry about running out of fuel</Card.Title>
              <Card.Text>
               Range is never an excuse,<br/> we got 100+km on single charge.
              </Card.Text>
             
            </Card.Body>
          </Card> 
            </Col>
          <Col>
            <Card className="b1"style={{ width: '18rem' }}>
            <Card.Img variant="top" src={img6} />
            <Card.Body>
              <Card.Title>Quick and fun to ride.</Card.Title>
              <Card.Text>
               Bicycle architure allows fantastic <br/>maneuverability in jam packed traffic.
              </Card.Text>
             
            </Card.Body>
          </Card>  
           
            </Col>
          </Row>
          </Col>
      </Row>
            
      
      
      <Row id="bx33">
          <Col lg={12} xl={6}  className='gr'>
          <Card id="bx4"  style={{ width: '18rem' ,marginLeft:"10%",marginTop:"10%",marginBottom:"10%" ,marginRight:"10%"}}>
            <Card.Img variant="top" src={img7} />
            
          </Card>             
          </Col>
          <Col id="bx3" lg={12} xl={6}  className='wh'>
            
            <div id="htb" style={{ margin:"10% 5% 10% 10%",textAlign:"left ",alignContent:""}}>
            <h1 >How to book?</h1>
            <div style={{fontSize:"1.2rem"}}><h4>Download the E Drive App:</h4>
            Download the app from Google play.<br/>
            <h4><br/>Book your Ride:</h4>        
            Select your Booking dates and pick your
            vehicle from your nearest pickup point.<br/><br/>
            <h4>Ride safely:</h4>
            Enjoy E Drive ride,but remember to abide by<br/>
            the traffic laws and parking policies.<br/>
            <h4><br/>End your Ride:</h4>
            Return back to our nearest drop point.
            </div>
            </div>
          
          </Col>
      </Row>
      <Row  style={{margin:'5% 0 5% 0'}}>
      <iframe width="100%" height="500px"   src="https://www.youtube.com/embed/qotaxeBdfNU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Row>
    
      <Row className='gr'>
      <Card style={{ margin:"3% 3% 3% 0%",width: '70%',}}>
        <Row>
          <Col sm={8}>
          <Card.Body style={{margin:"5% 0% 5% 0%",textAlign:"right"}}>
          
          {/* <Card.Text> */}
            <div style={{fontSize:"1.2rem"}}>My monthly expenditure on <br/>vehicle is reduced <br/>by 50%</div>
            {/* <div style={{fontSize:"1.5rem"}}>-Mr. Umesh</div> */}
            <h2>-Mr. Umesh</h2>
            <h3>Zomato</h3>
           
          {/* </Card.Text> */}
         
        </Card.Body>
          </Col>
          <Col sm={4}>
            <Card.Img variant="" style={{padding:"20% 0% 0% 10%" ,height:"80%",}}  src={img9} />

          </Col>
        </Row>
        
      </Card>
      <Card style={{ margin:"3% 3% 3% 30%",width: '70%',}}>
        <Row>
          <Col sm={4}>
            <Card.Img variant="" style={{padding:"20% 0% 0% 10%" ,height:"80%",}}  src={img8} />

          </Col>
          <Col sm={8}>
          <Card.Body style={{textAlign:"right"}}>
          
          {/* <Card.Text> */}
          <div>  

           <div style={{fontSize:"1.2rem"}}>Its quick & fun to ride <br/>gives fantastic maneuverability <br/>in jammed packed traffic.</div>
           <h2>-Mr. Rakesh</h2>
           <h3>Swiggy</h3>
          </div>
          {/* </Card.Text> */}
         
        </Card.Body>
          </Col>
         
        </Row>
        
      </Card>
     
      </Row>
      <Row  style={{margin:'5% 0 5% 0'}}>
      <iframe width="100%" height="500px"   src="https://www.youtube.com/embed/sG2Mkx8aLUs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Row>

    </>
</div>
    );}
  
}
export default Home
