import React, {useEffect,useState } from 'react'
// import logo from '../assets/svg/logo.svg';
import '../sass/app.scss';
import Home from './Home'
import Footer from './Footer'
import Nav from './Navcomp'
import { BrowserRouter, Route,Routes} from "react-router-dom";
import Invest from './Invest';
import Contact from './Contact'
import FAQ from './FAQ'
import Terms from './Terms'
import Privacy from './Privacy'
import { Container } from 'react-bootstrap';




function App() {

  const [isMobile, setIsMobile] = useState(3)
  //choose the screen size 
  const handleResize = () => {
    if (document.documentElement.clientWidth >= 1296) {
      setIsMobile(3)
  } else if(document.documentElement.clientWidth >= 800) {
      setIsMobile(2)
  }
  else
  {setIsMobile(1)}
  }
  
  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  useEffect(() => {
    handleResize()
  }, []);


  return (
    <div className="App">
      <Container fluid >
        
      <BrowserRouter>
        <Nav/>
        <Routes>
            {/* <Route path="/" element={<Nav/>} /> */}
            <Route path="/" element={<Home/>} />
            <Route path="/BuyandLease" element={<Invest ss={isMobile}/>} />
            <Route path="/Contact" element={<Contact/>} />
            <Route path="/FAQ" element={<FAQ ss={isMobile} />} />
            <Route path="/Terms" element={<Terms/>} />
            <Route path="/Privacy" element={<Privacy/>} />
            {/* <Route path="/" element={<Footer/>} /> */}
        </Routes>
        <Footer/>
      </BrowserRouter>
      </Container>
    </div>
  );
}

export default App

