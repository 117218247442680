import React,{useState,useEffect} from 'react';
import img10 from '../assets/logo/edrive_logo.png'
import { Row,Col,Image,Button} from 'react-bootstrap';
import {Link,useLocation} from "react-router-dom";
import '../sass/Homestyle.scss';

function Footer() {
      const [isMobile, setIsMobile] = useState(3)
      const [url, setUrl] = useState(window.location.href)
      var location=useLocation()
  //choose the screen size 
  const handleResize = () => {
    setUrl(window.location.href)

    if (document.documentElement.clientWidth >= 1296) {
      setIsMobile(3)
  } else if(document.documentElement.clientWidth >= 800) {
      setIsMobile(2)
  }
  else
  {setIsMobile(1)}
  }
  useEffect(()=>{
    setUrl(window.location.href);
  },[location])
  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  useEffect(() => {
    handleResize()
  }, []);

  if(url.indexOf('BuyandLease')===-1)
  {
    if(isMobile===1)
    {
        return (<div>
         <Row  className='footergrad'>
                  <footer>
                    <Row style={{margin:"5% 0 -45% 0"}}>
                    <Col xs={12}>
            <Image fluid="true" style={{height:"30%"}}src={img10} alt='E Drive Logo'/>
            
           </Col>
                    </Row>
         <Row style={{margin:"5% 0% 5% 0%"}}>
         
           <Col xs={6}>
              <p><Link className="fs" to="/Terms">Terms and Condition</Link></p>
              <p><Link className="fs" to="/Privacy">Privacy Condition</Link></p>
              <p><Link className="fs" to="/faq">FAQ</Link></p>
           </Col> 
           <Col xs={6}>
            <p style={{fontSize:"1.5rem"}}>Contact</p>
            <p><a href="mailto:suraj</br>@ecodrives.in" className="fs">suraj@<br/>ecodrives.in</a></p>
            <p><a href="tel:8867208322"className="fs">8867208322</a></p>
            
           </Col>
          
         </Row>
         <Row>
         <Col >
           <a href="https://www.facebook.com/ecodrives.in/?view_public_for=1124549377666418" className="fs"><i style={{marginLeft:"0"}}className="fab fa-facebook-square"></i></a>
           <a className="fs" href="https://www.instagram.com/edrives.in/"><i style={{marginLeft:"20%"}}className="fab fa-instagram"  ></i></a>
            <a className="fs" href="https://linkedin.com/company/eco-drives"><i style={{marginLeft:"20%"}}className="fab fa-linkedin-in" ></i></a>
          <a className="fs" href="https://www.youtube.com/channel/UC-YkDD2sqoIkoWIcY4qgNcg"><i style={{marginLeft:"20%"}}className="fab fa-youtube" ></i></a>
           
               
           </Col>
         </Row>
        </footer>

        </Row>
        </div>);
    }
    else 
    {
        return (
            <div>
            <Row  className='footergrad'>    
           <footer>
            <Row >
              <Col xs={3} style={{marginTop:"5%"}}>
                <Image fluid="true" style={{height:"30%"}}src={img10} alt='E Drive Logo'/>
               
              </Col>
              <Col xs={3} style={{marginTop:"5%"}}>
                  <p><Link className="fs" to="/Terms">Terms and Conditions</Link></p>
                  <p><Link className="fs" to="/Privacy">Privacy Conditions</Link></p>
                  <p><Link className="fs" to="/faq">FAQ</Link></p>
              </Col>
              <Col xs={3} style={{marginTop:"5%"}}>
                <h5>Contact</h5>
                <p className="d-none d-sm-block"><a href="mailto:suraj@edrives.in"className="fs">suraj@edrives.in</a></p>
                <p><a href="tel:8867208322" className="fs">8867208322</a></p>


                
              </Col>
              <Col xs={3} style={{marginTop:"5%"}}>
              <h4><a className="fs" href="https://www.facebook.com/ecodrives.in/?view_public_for=1124549377666418"><i className="fab fa-facebook-square" ></i></a></h4>
              <h4><a href="https://www.instagram.com/edrives.in/" className="fs"><i className="fab fa-instagram" ></i></a></h4>
              <h4> <a className="fs" href="https://linkedin.com/company/eco-drives"><i className="fab fa-linkedin-in" ></i></a></h4>
              <h4><a className="fs" href="https://www.youtube.com/channel/UC-YkDD2sqoIkoWIcY4qgNcg"><i className="fab fa-youtube" ></i></a></h4>
              
                  
              </Col>
            </Row>
            </footer>
            </Row>
            </div>
        );
    }
  }
  else
  {
    
    if(isMobile===1)
    {
        return (<div>
         <Row  className='footergrad'>
                  <footer>
         <Row style={{margin:"5% 0% 5% 0%"}}>
           <Col xs={4}>
            <Image fluid="true" style={{height:"30%",marginTop:"30%"}}src={img10} alt='E Drive Logo'/>
            
           </Col>
         
           <Col xs={4}>
            <h5>Contact</h5>
            <p><a href="mailto:suraj</br>@ecodrives.in" className="fs">suraj@<br/>edrives.in</a></p>
            <p><a href="tel:8867208322"className="fs">8867208322</a></p>
            
           </Col>
          
        
        
         <Col xs={4}>
           <div><a className="fs" href="https://www.facebook.com/ecodrives.in/?view_public_for=1124549377666418"><i className="fab fa-facebook-square" ></i></a></div>
           <div><a className="fs" href="https://www.instagram.com/edrives.in/"><i className="fab fa-instagram" ></i></a></div>
          <div>
          <a className="fs" href="https://linkedin.com/company/eco-drives"><i className="fab fa-linkedin-in" ></i></a>
          </div>
          <div>
          <a className="fs" href="https://www.youtube.com/channel/UC-YkDD2sqoIkoWIcY4qgNcg"><i className="fab fa-youtube" ></i></a>
          </div>           
               
           </Col>
         </Row>
        </footer>

        </Row>
        </div>);
    }
    else 
    {
        return (
            <div>
            <Row  className='footergrad'>    
           <footer>
            <Row >
              <Col xs={4} style={{marginTop:"5%"}}>
                <Image fluid="true" style={{height:"30%"}}src={img10} alt='E Drive Logo'/>
               
              </Col>
             
              <Col xs={4} style={{marginTop:"5%"}}>
                <h5>Contact</h5>
                <p className=""><a href="mailto:suraj@edrives.in" className="fs">suraj@edrives.in</a></p>
                <p><a href="tel:8867208322" className="fs">8867208322</a></p>

                
              </Col>
              <Col xs={4} style={{marginTop:"5%"}}>
              <p><a className="fs" href="https://www.facebook.com/ecodrives.in/?view_public_for=1124549377666418"><i className="fab fa-facebook-square" ></i></a></p>
              <p><a href="https://www.instagram.com/edrives.in/" className="fs"><i className="fab fa-instagram" ></i></a></p>
              <p> <a className="fs" href="https://linkedin.com/company/eco-drives"><i className="fab fa-linkedin-in" ></i></a></p>
              <p><a className="fs" href="https://www.youtube.com/channel/UC-YkDD2sqoIkoWIcY4qgNcg"><i className="fab fa-youtube" ></i></a></p>
              
                  
              </Col>
            </Row>
            </footer>
            </Row>
            </div>
        );
    }
  }
    
}


export default Footer;
